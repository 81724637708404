<template>
	<div>
		<TableList @changeStatus='get_list_Count' :form_data_seo.sync="form_data_seo" :columns="columns"
			:get_table_list="this.get_table_list"
			:slot_table_list="['operation','status','name','use_num','execute_end_time']"
			:submit_preprocessing="submit_preprocessing" ref="list">
			<template slot="form_after">
				<div class='page_content_top page_content'>
					<div v-for='item in top_count'>
						<a-statistic :title="item.label" :value="item.value" />
					</div>
				</div>
			</template>
			<template slot="left_btn">
				<a-button class="float_left"  @click="edit" type="primary">添加规则</a-button>
			</template>
			<template slot="operation" slot-scope="data">
				<p>
					<span>
						<a @click='edit(data.record)'>
							编辑
						</a>
						<a-divider type="vertical" />
						<a-popconfirm title="您确定要删除吗?" @confirm="del(data.record)">
							<a>
								删除
							</a>
						</a-popconfirm>
					</span>
				</p>
			</template>
			<template slot="name" slot-scope="data">
				<p>
					<span>

						<a @click='see_rule(data.record)'>
							{{data.record.name}}
						</a>
					</span>
				</p>
			</template>
			<template slot="status" slot-scope="data">
				<p>
					<a-switch @change='changeStatus(data.record)' v-model:checked="data.record.status==1?true:false" />
				</p>
			</template>
			<template slot="use_num" slot-scope="data">
				<p>
					<span>
						<a @click='see_user(data.record)'>
							{{data.record.use_num}}
						</a>
					</span>
				</p>
			</template>
			<template slot="execute_end_time" slot-scope="data">
				<p>
					<span>
					白班：{{data.record.day_start_time}}~{{data.record.day_end_time}}
					</span>
				</p>
				<p>
					<span>
					晚班：{{data.record.night_start_time}}~{{data.record.night_end_time}}
					</span>
				</p>
			</template>
		</TableList>
		
		<a-modal title="查看规则" :maskClosable='true' :visible="see_visible" @cancel='see_visible=false'
			@ok='see_visible=false'>
			<div>
				<p class='info_item'><span>规则名称：</span><span>{{see_info.name}}</span></p>

				<p class='info_item'><span>白班合格时长：</span><span>{{see_info.day_pass_time}}小时</span></p>

				<p class='info_item'><span>晚班合格时长：</span><span>{{see_info.night_pass_time}}小时</span></p>

				<p class='info_item'><span>月应合格天数：</span><span>{{see_info.pass_num}}天</span></p>

				<p class='info_item'><span>月晚班应执行天数：</span><span>{{see_info.night_pass_num}}天</span></p>

				<p class='info_item'><span>免执行天数：</span><span>{{see_info.unneed_day}}天</span></p>

				<p class='info_item'>
					<span>白班执行时间：</span><span>{{see_info.day_start_time+'~'+see_info.day_end_time}}</span>
				</p>

				<p class='info_item'>
					<span>晚班执行时间：</span><span>{{see_info.night_start_time+'~'+see_info.night_end_time}}</span>
				</p>

				<p class='info_item'><span>创建时间：</span><span>{{see_info.create_time}}</span></p>

				<p class='info_item'><span>状态：</span><span>{{see_info.status==1?'执行中':'禁用中'}}</span></p>
			</div>
		</a-modal>
		<supInfo :visible.sync="drawer_visible">
		</supInfo>
		<PopTableList :where='where' :slot_table_list="['department']"  rowKey="id" ref="pop_table_list" :columns="columns_details"
			:get_table_list="get_user_list" :visible.sync="visible_see">
			<template slot="department" slot-scope="data">
				<span>{{data.record.department_join.department_join}}</span>
			</template>
		</PopTableList>
	</div>
</template>
<script>
	import {
		user_index
	} from "@/api/user";

	import PopTableList from "@/components/PopTableList";
	import Form from "@/components/Form";
	import TableList from "@/components/TableList";
	
	import TransferTree from "@/components/TransferTreeTwo";
	import supInfo from "@/views/supervision/supInfo.vue";
	import {
		message
	} from 'ant-design-vue';
	import {
		getRuleList,
		getListCount,
		saveRuleInfo,
		getRuleDetail,
		changeRuleStatus,
		delRule
	} from "@/api/supervision";
	let columns_details = [{
			title: "姓名",
			dataIndex: "username",
		},
		{
			title: "部门",
			dataIndex: "department",
			scopedSlots: {
				customRender: "department"
			}
		}, {
			title: "岗位",
			dataIndex: "role_name",
		},
		{
			title: "电话",
			dataIndex: "phone",
		},
	]
	let columns = [{
			title: "规则名称",
			dataIndex: "name",
			scopedSlots: {
				customRender: "name"
			}
		},
		{
			title: "使用人员数",
			dataIndex: "use_num",
			scopedSlots: {
				customRender: "use_num"
			}
		},
		{
			title: "执行时间",
			dataIndex: "execute_end_time",
			scopedSlots: {
				customRender: "execute_end_time"
			}
		},
		{
			title: "状态",
			dataIndex: "status",
			scopedSlots: {
				customRender: "status"
			}
		},
		{
			title: "创建时间",
			dataIndex: "create_time",
		},
		{
			title: "更新时间",
			dataIndex: "update_time",
		},
		{
			title: "操作",
			scopedSlots: {
				customRender: "operation"
			}
		},
	];

	export default {
		name: "gpoodIndex",
		components: {
			Form,
			TableList,
			TransferTree,
			PopTableList,
			supInfo
		},
		data() {
			return {
				see_info: {},
				see_visible: false,
				drawer_visible: true,
				where: {
					rule_id: ''
				},
				execute_start_time: '',
				execute_end_time: '',
				treeData: [],
				rule_user: [],
				treeDataValue: [],
				top_count: [],
				get_user_list: user_index,
				get_table_list: getRuleList,
				columns,
				columns_details,
				treeDataValue: [],
				visible: false,
				visible_see: false,
				form_data_seo: {
					list: [{
							type: 'text',
							name: 'keyword',
							placeholder: '请输入规则名称',
							title: '名称',
						},
						{
							type: 'select',
							name: 'status',
							placeholder: '请选择',
							title: '状态',
							list: [{
									key: 1,
									value: '启用中'
								}, {
									key: -1,
									value: '停用中'
								},
								{
									key: '',
									value: '全部'
								}
							],
						},
						{
							type: "range_date",
							name: "range_date",
							title: "创建时间",
							options: {},
							start: {
								name: 'start_time'
							},
							end: {
								name: 'end_time'
							},
						},

					],
					...this.$config.form_data_seo
				},
				submit_preprocessing: {
					array_to_string: ['goods_name']
				}
			};
		},
		async created() {
			this.get_list_Count()
		},
		methods: {
			see_rule(data) {
				getRuleDetail({
					data: {
						id: data.id
					}
				}).then(res => {
					this.see_info = res.data.list
					this.see_visible = true
				})
			},
			show() {
				this.drawer_visible = true;
			},
			see_user(data) {
				this.visible_see = true
				this.where.rule_id = data.id
				this.$nextTick(res => {
					this.$refs.pop_table_list.set_data('fixed_seo_data', {
						rule_id: data.id
					})
					this.$refs.pop_table_list.get_list();
				})
			},
			TransferTreeChange(value) {
				this.rule_user = value.join(',')
			},
			changeStatus(data) {
				changeRuleStatus({
					data: {
						id: data.id
					}
				}).then(res => {
					this.$refs.list.get_list();
				})
			},
			get_list_Count(data) {
				getListCount({
					data: data ? data : {}
				}).then(res => {
					this.top_count = res.data.list
				})
			},
			edit(record) {
				this.$router.push({
					path: 'edit',
					query: {
						id: record.id ? record.id : ''
					}

				})
			},
			del(record) {
				delRule({
					data: {
						id: record.id
					},
					info: true,
				}).then(res => {
					this.get_list_Count()
					this.$refs.list.get_list();
				})
			},
			submit(e) {
				let data = e.values;
				if (!data.id) {
					data.id = '';
				}
				
				saveRuleInfo({
					data: {
						...data,
						
						execute_end_time: this.execute_end_time,
						execute_start_time: this.execute_start_time,
						rule_user: this.rule_user
					},
					info: true,
				}).then(res => {
					this.visible = false;
					this.$refs.list.get_list();
				})
			},

		}
	};
</script>

<style lang="less">
	@import url("../../assets/less/app.less");

	.max_price_tips {
		margin-left: 99px;
		margin-top: -30px;
		font-size: 13px;
	}

	.float_left {
		margin-right: 8px;
	}

	.max_price_form {
		.item {
			display: flex;

			.right {
				flex: 1;

				.input_number {
					width: 100%;
				}
			}
		}
	}

	.page_content_top {
		display: flex;

		div {
			flex: 1;
			text-align: center;
		}

		div:nth-child(3) {
			cursor: pointer;
		}
	}

	.info_item {
		display: flex;
		font-size: 16px;
		line-height: 35px;

		span {
			flex: 1;
			text-align: right;
		}

		span:nth-child(2) {
			flex: 2;
			text-align: left;
			margin-left: 10px;
		}
	}
</style>